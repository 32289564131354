const DEFAULT_LOGGER_PIXEL_URL = "https://prod.log.shortbread.aws.dev/1x1.png";
const DEFAULT_TIMEOUT_MILLIS = 5000;
const LOGGER_VERSION = "1";
interface LoggerOptions {
    baseUrl?: string,
    timeout?: number,
    backoffDelays?: number[],
    onSuccess?: (url?: string) => void,
    onFail?: () => void,
    onError?: (status?: string) => void,
}
export interface Log {
    (severity: Severity): (message: string, payload?: object) => void
}
export type Severity = "error" | "warning" | "info";
const loggerHttpRequest = (url: string, timeout: number) => {
    try {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {}
        xhr.ontimeout = () => {}
        xhr.onerror = () => {}
        xhr.open("HEAD", url);
        // setting the timeout after the .open() to fix InvalidStateError on IE 11
        // based on the suggestion here: https://github.com/stephanebachelier/superapi/issues/5
        xhr.timeout = timeout;
        xhr.send();
    } catch (e) {

    }
}

const makeUrlString = (baseUrl: string, severity, message: string, payload = {}):string => {
    const payloadData = {
        timestamp: Date.now(),
        logVersion: LOGGER_VERSION,
        domain: window.location.host,
        url: window.location.href,
        ...payload
    }
    const paramString = `severity=${encodeURIComponent(severity)}&message=${encodeURIComponent(message)}&payload=${encodeURIComponent(JSON.stringify(payloadData))}`
    return baseUrl + "?" + paramString;
}
// url into the log
const createLogger = (options: LoggerOptions): Log => {
    const {
        baseUrl = DEFAULT_LOGGER_PIXEL_URL,
        timeout = DEFAULT_TIMEOUT_MILLIS
    } = options
    return (severity) => (message, payload = {}) => {
        const url = makeUrlString(baseUrl, severity, message, payload)
        // Make sure to create new array for backoffDelays to prevent mutation of the reference
        loggerHttpRequest(url, timeout);
    };
}

export const DEFAULT_LOGGER = createLogger({baseUrl: "https://prod.log.shortbread.aws.dev/1x1.png"});
export default createLogger
