import {v4 as uuidv4} from "uuid"
import {Log} from "./logger";

export default (
    log: Log,
    uuidGenerator: () => string = uuidv4,
    uuidFallback: () => string = () => `ts-${Date.now().toString()}`
) => {
    const error = log ? log("error") : () => {};
    try {
        return uuidGenerator()
    } catch (err) {
        error("uuid", {detail: "Error generating UUID", errorMessage: err.message || ""})
        return uuidFallback()
    }
}