import {act, render} from "../../jsx-act";
import Button from "../utilities/button"
import UXDefinitions from "../../definitions/ux-definitions"
import CookieDefinitions from "../../definitions/cookie-definitions"
import { BANNER_ID, BANNER_ACCEPT_BTN_ID, BANNER_CUSTOMIZE_BTN_ID  } from "../../constants/ux-constants";
import './consent-banner.scss'

interface ConsentBannerProps {
    showConsentSelector:  UXDefinitions.HandleShowConsent,
    handleSaveClick: UXDefinitions.HandleSaveClick,
    localizedText: UXDefinitions.ConsentBannerLocalization,
    hasConsoleNavFooter? : boolean
}
export default ({showConsentSelector, handleSaveClick, localizedText, hasConsoleNavFooter = false}: ConsentBannerProps) => {
    const AcceptAllCookie: CookieDefinitions.ConsentCookie = {
        essential: true,
        functional: true,
        performance: true,
        advertising: true
    }

    //accounts of console nav's footer by using a global css class name from console
    //adds some bottom offset to the fixed element also reduces z-index from covering console nav footer's language selector
    let bannerContainerClass = hasConsoleNavFooter === true ? "awsccc-tab-helper awsc-bot-above-f-imp" :  "awsccc-tab-helper";

    return (
        <div data-id={BANNER_ID} style={{display: 'none'}}>
            <div id="awsccc-cb-c" data-id="awsccc-cb-tabstart" class={bannerContainerClass} tabindex="-1">
                <div id="awsccc-cb-content">
                    <h2 id="awsccc-cb-title">{localizedText.title}</h2>
                    <div id="awsccc-cb-actions">
                        <p id="awsccc-cb-text">{localizedText.paragraph}</p>
                        <div id="awsccc-cb-buttons">
                            <Button
                                dataId={BANNER_CUSTOMIZE_BTN_ID}
                                variant="secondary"
                                text={localizedText["button-customize"]}
                                events={{onclick: () => showConsentSelector("consentBanner")}}
                                props={{"aria-label": localizedText["button-customize-aria-label"]}}/>
                            <Button
                                dataId={BANNER_ACCEPT_BTN_ID}
                                variant="primary"
                                text={localizedText["button-accept"]}
                                events={{onclick: () => handleSaveClick(AcceptAllCookie, "consentBanner")}}
                                props={{"aria-label": localizedText["button-accept-aria-label"]}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
