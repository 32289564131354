import { render, act } from "../jsx-act";
import ConsentBanner from "./consent-banner/consent-banner";
import ConsentSelector from "./consent-selector/consent-selector";
import UXDefinitions from '../definitions/ux-definitions'
import CookieDefinitions, { COOKIE_CATEGORIES, ESSENTIAL } from '../definitions/cookie-definitions'
import { APP_ID, BANNER_ID, CONTAINER_ID, CUSTOMIZE_ID, TABTRAP_ID } from "../constants/ux-constants";
import { localizationDictionary, localizationRtl } from "../constants/localization-dictionary";
import { getConsentCookie as getConsentCookieCore } from "../cookie";
import { Log } from "../logger";
import "./ux-components.scss"
import { convertToArray } from "../utils";
import { DEFAULT_COOKIE } from "../constants/cookie-constants";
import domReady from "../ready";
import LogSources = UXDefinitions.LogSources;

interface ShortbreadUiOptions {
    parent: HTMLElement | undefined,
    language: UXDefinitions.LanguageCodesNormalized,
    onSaveConsent: (consent: CookieDefinitions.ConsentCookie) => void,
    getConsentCookie: () => ReturnType<typeof getConsentCookieCore>,
    log: Log,
    onModalClose?: () => void,
    hasConsoleNavFooter?: boolean
}

export function isChecked(category: CookieDefinitions.CookieCategory): boolean {
    return document
        .querySelector(`label[data-id=awsccc-u-cb-${category}-label] input`)!
        .getAttribute("checked") === "";
}

function renderUi(options: ShortbreadUiOptions) {
    const localizedText: UXDefinitions.LocalizationSet = localizationDictionary[options.language]
    const isRtl = localizationRtl.indexOf(options.language) > -1;
    const conditionalClasses = isRtl ? "awsccc-Rtl" : "";

    function _getBannerEl(): HTMLElement {
        return document.querySelector<HTMLElement>(`div[data-id=${BANNER_ID}]`)!
    }
    function _getConsentModalEl(): HTMLElement {
        return document.querySelector<HTMLElement>(`div[data-id=${CUSTOMIZE_ID}]`)!
    }

    function setCheckboxStatus(category: CookieDefinitions.CookieCategory, on: boolean) {
        const labelEl = document.querySelector(`label[data-id=awsccc-u-cb-${category}-label]`);
        const classList = labelEl!.classList;
        const inputEl = labelEl!.querySelector("input")
        if (on) {
            inputEl!.setAttribute("checked", "")
            classList.add("awsccc-u-cb-checkbox-active");
        } else {
            classList.remove("awsccc-u-cb-checkbox-active");
            inputEl!.removeAttribute("checked")
        }
        inputEl!.setAttribute("aria-checked", `${on}`);
    }

    const handleCheckboxToggle: UXDefinitions.HandleCheckboxToggle = ({ event, category }) => {
        if (event.target.getAttribute("type") === "checkbox" || event.target.getAttribute("class") === "awsccc-cs-s-title") {
            setCheckboxStatus(category, !isChecked(category));
        }
    }

    const handleSaveClick = (context: string) => (cookie: CookieDefinitions.ConsentCookie, source) => {
        const firstTabEl = _getBannerEl().querySelector<HTMLElement>(`div[data-id=awsccc-cb-tabstart]`)
        const consentSelectorEl = document.querySelector(`div[data-id=${CUSTOMIZE_ID}]`) as HTMLElement
        consentSelectorEl!.style.display = 'none';
        _getBannerEl().style.display = 'none';
        firstTabEl!.setAttribute("tabindex", "-1")
        options.onSaveConsent(cookie);
        document.body.classList.remove("awsccc-cs-modal-open");
        options.log("info")(context, { detail: "Save Consent Clicked", source, cookie: options.getConsentCookie() });
    }

    const showBanner: UXDefinitions.HandleShowBanner = () => {
        const firstTabEl = _getBannerEl().querySelector<HTMLElement>(`div[data-id=awsccc-cb-tabstart]`)
        _getBannerEl().style.display = 'block';
        firstTabEl!.setAttribute("tabindex", "0")
        firstTabEl!.focus({
            preventScroll: true
        });
    }

    const handleEscapeKeyPress = (event) => {
        if (event.key === 'Escape') {
            closeConsentSelector()
        }
    };

    const getCookie = () => {
        return options.getConsentCookie() || DEFAULT_COOKIE;
    }

    const updateCheckboxStatusFromCookie = () => {
        const cookie = getCookie();

        COOKIE_CATEGORIES.filter(c => c !== ESSENTIAL).forEach(category => {
            setCheckboxStatus(category, cookie[category]);
        });
    }

    const showConsentSelector: UXDefinitions.HandleShowConsent = (source) => {
        updateCheckboxStatusFromCookie();

        _getConsentModalEl().addEventListener('keydown', handleEscapeKeyPress)
        _getConsentModalEl().style.display = 'block';
        document.body.classList.add("awsccc-cs-modal-open")
        /**
         * When the customize modal is closed, by default
         * we set the tab traps to -1 to prevent users from
         * tabbing to the modal while it is closed. When users
         * open the modal, we set the tabindex to 0 to make contents
         * focusable. We also default focus to the first element in the modal.
         */
        const nodes = document.querySelectorAll(`div[data-id=${TABTRAP_ID}]`)
        convertToArray(nodes).forEach(function (el: HTMLElement, i: number) {
            if (i === 0) {
                el.focus({
                    preventScroll: true
                });
            }
            el.setAttribute("tabindex", "0");
        });
        options.log("info")("customizeCookies", { detail: "Customize Consent Clicked", source, cookie: options.getConsentCookie() });
    };

    const closeConsentSelector = () => {
        _getConsentModalEl().removeEventListener('keydown', handleEscapeKeyPress)
        _getConsentModalEl().style.display = 'none';
        document.body.classList.remove("awsccc-cs-modal-open")
        /**
         * Ensures that the modal cannot be tabbed when
         * the modal is closed.
         */
        const nodes = _getConsentModalEl().querySelectorAll(`div[data-id=${TABTRAP_ID}]`)
        convertToArray(nodes).forEach(function (el) {
            el.setAttribute("tabindex", "-1");
        });
        /**
         * Accessibility - If modal is closed but banner is still open
         * which occurs when a user cancels, focus back onto the banner
         */
        if (_getBannerEl().style.display === "block") {
            const firstTabEl = _getBannerEl().querySelector<HTMLElement>(`div[data-id=awsccc-cb-tabstart]`)
            firstTabEl!.focus({
                preventScroll: true
            });
        }
        /**
         * Accessibility - optional function that can be called after closing the modal.
         * Can be used to focus on another element that opened the modal.
         */
        if (options.onModalClose) {
            options.onModalClose()
        }
    };

    domReady(() => {
        if (document.querySelector(`#${CONTAINER_ID}`)) {
            // The Shortbread components have already been placed in the DOM, no need to add them again
            return;
        }

        render(options.parent || document.body,
            <div id={CONTAINER_ID}>
                <div id={APP_ID} class={conditionalClasses}>
                    <ConsentBanner
                        showConsentSelector={showConsentSelector}
                        handleSaveClick={handleSaveClick("acceptAll")}
                        localizedText={localizedText.consentBanner}
                        hasConsoleNavFooter={options.hasConsoleNavFooter}
                    />
                    <ConsentSelector
                        consentState={getCookie()}
                        handleSaveClick={handleSaveClick("customize")}
                        handleCheckboxToggle={handleCheckboxToggle}
                        localizedText={localizedText.consentSelector}
                        closeConsentSelector={closeConsentSelector}
                        darkModeEnabled={options.hasConsoleNavFooter}
                    />
                </div>
            </div>
        );
    });

    return {
        showConsentSelector(source: LogSources | undefined) {
            domReady(() => {
                showConsentSelector(source);
            })
        },
        showBanner(andThen: () => void) {
            domReady(() => {
                showBanner();
                andThen();
            });
        },
    };
}

export default {
    createShortbreadUi(options: ShortbreadUiOptions) {
        return renderUi(options);
    },
};