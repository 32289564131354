import {act} from "../../jsx-act";
import Button from "../utilities/button";
import UXDefinitions from "../../definitions/ux-definitions";
import {CUSTOMIZE_CANCEL_BTN_ID, CUSTOMIZE_SAVE_BTN_ID} from "../../constants/ux-constants";
import CookieDefinitions from "../../definitions/cookie-definitions";

import "./consent-footer.scss"
import {isChecked} from "../ux-components";

interface ContentFooterProps {
    handleSaveClick: UXDefinitions.HandleSaveClick,
    handleCancelClick: () => void,
    localizedText: UXDefinitions.ConsentSelectorLocalization,
}
export default ({handleSaveClick, handleCancelClick, localizedText}: ContentFooterProps) => {
    function getConsentStateFromDom(): CookieDefinitions.ConsentCookie {
        return {
            essential: true,
            performance: isChecked("performance"),
            functional: isChecked("functional"),
            advertising: isChecked("advertising"),
        };
    }

    function saveClicked() {
        handleSaveClick(getConsentStateFromDom(), "preferencesModal");
    }

    return (
        <div id="awsccc-cs-f-c">
            <Button dataId={CUSTOMIZE_CANCEL_BTN_ID} variant="secondary" events={{onclick: handleCancelClick}}
                    text={localizedText["button-cancel"]}
                    props={{"aria-label": localizedText["button-cancel-aria-label"]}}/>
            <Button dataId={CUSTOMIZE_SAVE_BTN_ID} variant="primary"
                    events={{onclick: saveClicked}} text={localizedText["button-save"]}
                    props={{"aria-label": localizedText["button-save-aria-label"]}}/>
        </div>
    );
}