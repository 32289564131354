import shortbread from "./ux-components/ux-components";
import { getConsentCookie, setConsentCookie } from "./cookie";
import { ALL_ALLOWED, DEFAULT_COOKIE, DEFAULT_COOKIE_AGE, DEFAULT_LANGUAGE } from "./constants/cookie-constants";
import CookieDefinitions, { COOKIE_CATEGORIES } from "./definitions/cookie-definitions";
import UXDefinitions from './definitions/ux-definitions';
import { localizationDictionary } from "./constants/localization-dictionary";
import queryGeolocationByHttpGetRequest, {
    GeolocationQuery,
    GeolocationRegion
} from "./geolocation";
import uuid from "./uuidInterface";
import { DEFAULT_LOGGER, Log } from "./logger";

export type Category = "essential" | "performance" | "functional" | "advertising";

export interface RegistryEntry {
    category: Category;
}

export interface Registry {
    [key: string]: RegistryEntry
}

export interface ShortbreadOptions {
    onBannerShown?: () => void;
    queryGeolocation?: GeolocationQuery,
    parent?: HTMLElement;
    language?: UXDefinitions.LanguageCodeOptions,
    onConsentChanged?: (c: CookieDefinitions.ConsentCookie) => void;
    domain?: string;
    registry?: Registry
    log?: Log;
    onModalClose?: () => void;
    hasConsoleNavFooter?: boolean;
}

interface InternalShortbreadOptions extends ShortbreadOptions {
    __storeWriter?: (text: string) => void;
    __storeReader?: () => string;
    __uuidGenerator?: () => string;
    __uuidFallback?: () => string;
}

export type OnAllowed = (name: string, entry: RegistryEntry) => any;
type AllowedEventListenerMap = {
    [category in Category]?: (() => void)[];
};

const onAllowedEventListeners: AllowedEventListenerMap = {
};

function fireOnAllowedEvents(consent: CookieDefinitions.ConsentCookie) {
    (Object.keys(onAllowedEventListeners) as Category[]).forEach(category => {
        if (consent[category]) { // this category is switched on
            const listeners = onAllowedEventListeners[category];
            listeners && listeners.forEach(listener => {
                listener();
            })
        }
    });
    COOKIE_CATEGORIES.filter(c => consent[c]).forEach(c => {
        onAllowedEventListeners[c] = []; // prevent further invocations
    });
}

// We use a document CustomEvent listener which gets triggered so that multiple Shortbreads will receive this event
function listenForConsentChangedCustomEvent(onConsentChanged: ((c: CookieDefinitions.ConsentCookie) => void) | undefined) {
    const listener = (event: CustomEvent) => {
        const consentCookie = event.detail as CookieDefinitions.ConsentCookie;
        fireOnAllowedEvents(consentCookie);
        onConsentChanged && onConsentChanged(consentCookie);
    };
    document.addEventListener(CONSENT_COOKIE_CHANGED_EVENT, listener);
    return listener;
}

export const CONSENT_COOKIE_CHANGED_EVENT = "cookie-consent-changed";
function fireConsentChanged(consentCookie: CookieDefinitions.ConsentCookie) {
    const event = document.createEvent("CustomEvent");
    event.initCustomEvent(CONSENT_COOKIE_CHANGED_EVENT, false, false, consentCookie);
    document.dispatchEvent(event);
}

const onSaveConsent = (options: InternalShortbreadOptions, log?: Log) => (consentCookie: CookieDefinitions.ConsentCookie) => {
    setConsentCookie(
        consentCookie,
        options.domain,
        DEFAULT_COOKIE_AGE,
        uuid,
        options.__storeWriter,
        log,
        options.__uuidGenerator,
        options.__uuidFallback
    );
    fireConsentChanged(consentCookie);
}

function checkNameIsInRegistry(registry: Registry | undefined, name: string, log: Log) {
    if (!registry) {
        log("error")("checkNameIsInRegistry", { detail: "AWSCC: No registry configured" });
        throw Error(`AWSCC: No registry configured`);
    }
    if (!registry[name]) {
        log("error")("checkNameIsInRegistry", { detail: `AWSCC: No such entry ${name} is in the registry` });
        throw Error(`AWSCC: No such entry ${name} is in the registry`);
    }
}

/**
 * This function will allow Shortbread to accept
 * language codes that may be valid but have
 * different casing, or a dash instead of underline to separate
 */
function validateLocalization(localizationCode: any): UXDefinitions.LanguageCodesNormalized {
    if (localizationCode && typeof localizationCode === "string") {
        // dash and em-dash
        const localePunctuation = /[–_]/;
        const normalizedCode = localizationCode.toLowerCase().replace(localePunctuation, "-")
        if (localizationDictionary[normalizedCode]) {
            return normalizedCode as UXDefinitions.LanguageCodesNormalized
        } else {
            return DEFAULT_LANGUAGE
        }
    }
    // Catch All
    return DEFAULT_LANGUAGE
}

export const AWSCShortbread = (options: ShortbreadOptions = {}) => {
    const o = options as InternalShortbreadOptions;
    const log = o.log || DEFAULT_LOGGER;
    const shortbreadUi = shortbread.createShortbreadUi({
        parent: o.parent,
        language: validateLocalization(o.language),
        onSaveConsent: onSaveConsent(o, log),
        getConsentCookie: () => getConsentCookie(o.__storeReader, log),
        log,
        onModalClose: o.onModalClose,
        hasConsoleNavFooter: o.hasConsoleNavFooter || false,
    });

    function checkAndFirePreexistingCookie() {
        const consentCookie = getConsentCookie(o.__storeReader, log);
        if (consentCookie) {
            fireConsentChanged(consentCookie);
            return consentCookie;
        }
        return consentCookie;
    }

    const consentChangedListener = listenForConsentChangedCustomEvent(o.onConsentChanged);

    return {
        checkForCookieConsent() {
            const cookie = checkAndFirePreexistingCookie();
            log("info")("checkForCookieConsent", cookie ? { cookie } : { status: "Consent cookie not present" });
            if (cookie) {
                return;
            }

            const queryGeolocation = o.queryGeolocation || queryGeolocationByHttpGetRequest();
            queryGeolocation(
                (region: GeolocationRegion) => {
                    // Because this is async, the cookie may have been set, so check cookie status again.
                    if (checkAndFirePreexistingCookie()) {
                        return;
                    }
                    if (region === "EU") {
                        shortbreadUi.showBanner(() => {
                            log("info")("bannerShown", { region });
                            o.onBannerShown && o.onBannerShown();
                        });
                    } else {
                        // not in the EU
                        const oneDayInSeconds = 86400;
                        const consentCookie = setConsentCookie(
                            { ...ALL_ALLOWED },
                            o.domain,
                            oneDayInSeconds,
                            uuid,
                            o.__storeWriter,
                            log,
                            o.__uuidGenerator,
                            o.__uuidFallback
                        );
                        fireConsentChanged(consentCookie);
                    }
                },
                log,
            );
        },
        customizeCookies() {
            shortbreadUi.showConsentSelector("manualTrigger");
        },
        getConsentCookie() {
            return getConsentCookie(o.__storeReader, log);
        },
        access(name: string, onAllowed: OnAllowed) {
            checkNameIsInRegistry(o.registry, name, log);
            const category = o.registry![name].category;
            if (!onAllowedEventListeners[category]) {
                onAllowedEventListeners[category] = [];
            }
            onAllowedEventListeners[category]!.push(() => onAllowed(name, o.registry![name]));
            const preExistingConsentCookie = getConsentCookie(o.__storeReader, log);
            if (preExistingConsentCookie) {
                fireOnAllowedEvents(preExistingConsentCookie);
            }
        },
        hasConsent(name: string): boolean {
            checkNameIsInRegistry(o.registry, name, log);
            const consentCookie = getConsentCookie(o.__storeReader, log) || { ...DEFAULT_COOKIE };
            return consentCookie[o.registry![name].category];
        },
        // Remove Shortbread event listeners
        __close() {
            document.removeEventListener(CONSENT_COOKIE_CHANGED_EVENT, consentChangedListener);
        }
    };
}

export type AWSCShortbread = Omit<ReturnType<typeof AWSCShortbread>, "__close">;
export default AWSCShortbread as any as AWSCShortbread;